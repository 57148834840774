import "@material/mwc-button";
import { mdiCastConnected, mdiCast } from "@mdi/js";
import "@polymer/paper-input/paper-input";
import {
  Auth,
  Connection,
  createConnection,
  ERR_CANNOT_CONNECT,
  ERR_HASS_HOST_REQUIRED,
  ERR_INVALID_AUTH,
  ERR_INVALID_HTTPS_TO_HTTP,
  getAuth,
  getAuthOptions,
} from "home-assistant-js-websocket";
import { css, CSSResultGroup, html, LitElement, TemplateResult } from "lit";
import { customElement, state } from "lit/decorators";
import {
  loadTokens,
  saveTokens,
} from "../../../../src/common/auth/token_storage";
import "../../../../src/components/ha-svg-icon";
import "../../../../src/layouts/hass-loading-screen";
import "./bmw-layout";

const seeFAQ = (qid) => html`
  See <a href="./faq.html${qid ? `#${qid}` : ""}">the FAQ</a> for more
  information.
`;
const translateErr = (err) =>
  err === ERR_CANNOT_CONNECT
    ? "Unable to connect"
    : err === ERR_HASS_HOST_REQUIRED
    ? "Please enter a Home Assistant URL."
    : err === ERR_INVALID_HTTPS_TO_HTTP
    ? html`
        Cannot connect to Home Assistant instances over "http://".
        ${seeFAQ("https")}
      `
    : `Unknown error (${err}).`;

const INTRO = html`
  <p>
    Home Assistant for iDrive allows you to access and control your home from
    your car.
  </p>
  <p>
    For more information, see the
    <a href="./faq.html">frequently asked questions</a>.
  </p>
`;


@customElement("bmw-connect")
export class BmwConnect extends LitElement {
  @state() private loading = false;
  // If we had stored credentials but we cannot connect,
  // show a screen asking retry or logout.
  @state() private cannotConnect = false;
  @state() private error?: string | TemplateResult;
  @state() private auth?: Auth;
  @state() private connection?: Connection;

  protected render(): TemplateResult | void {
    if (this.cannotConnect) {
      const tokens = loadTokens();
      return html`
        <bmw-layout>
          <div class="card-content">
            Unable to connect to ${tokens!.hassUrl}.
          </div>
          <div class="card-actions">
            <a href="/">
              <mwc-button>
                Retry
              </mwc-button>
            </a>
            <div class="spacer"></div>
            <mwc-button @click=${this._handleLogout}>Log out</mwc-button>
          </div>
        </bmw-layout>
      `;
    }

    if (this.loading) {
      return html` <loading-screen></loading-screen> `;
    }

    if (!this.auth) {
      return html`
        <bmw-layout>
          <div class="card-content">
            ${INTRO}
            <p>
              To get started, enter your Home Assistant URL and click authorize.
            </p>
            <p>
              <paper-input
                label="Home Assistant URL"
                placeholder="https://abcdefghijklmnop.ui.nabu.casa"
                @keydown=${this._handleInputKeyDown}
              ></paper-input>
            </p>
            ${this.error ? html` <p class="error">${this.error}</p> ` : ""}
          </div>
          <div class="card-actions">
            <div class="spacer"></div>
            <mwc-button @click=${this._handleConnect}>Authorize</mwc-button>
          </div>
        </bmw-layout>
      `;
    }

    return html`
      <bmw-bmw .connection=${this.connection} .auth=${this.auth}></bmw-bmw>
    `;
  }

  protected firstUpdated(changedProps) {
    import("./bmw-bmw");
    super.firstUpdated(changedProps);

    if (location.search.indexOf("auth_callback=1") !== -1) {
      this._tryConnection("auth-callback");
    } else if (loadTokens()) {
      this._tryConnection("saved-tokens");
    }
  }

  private _handleInputKeyDown(ev: KeyboardEvent) {
    // Handle pressing enter.
    if (ev.keyCode === 13) {
      this._handleConnect();
    }
  }

  private async _handleConnect() {
    const inputEl = this.shadowRoot!.querySelector("paper-input")!;
    const value = inputEl.value || "";
    this.error = undefined;

    if (value === "") {
      this.error = "Please enter a Home Assistant URL.";
      return;
    } else if (value.indexOf("://") === -1) {
      this.error =
        "Please enter your full URL, including the protocol part (https://).";
      return;
    }

    let url: URL;
    try {
      url = new URL(value);
    } catch (err) {
      this.error = "Invalid URL";
      return;
    }

    if (url.protocol === "http:" && url.hostname !== "localhost") {
      this.error = translateErr(ERR_INVALID_HTTPS_TO_HTTP);
      return;
    }
    await this._tryConnection("user-request", `${url.protocol}//${url.host}`);
  }

  private async _tryConnection(
    init: "auth-callback" | "user-request" | "saved-tokens",
    hassUrl?: string
  ) {
    const options: getAuthOptions = {
      saveTokens,
      loadTokens: () => Promise.resolve(loadTokens()),
    };
    if (hassUrl) {
      options.hassUrl = hassUrl;
    }
    let auth: Auth;

    try {
      this.loading = true;
      auth = await getAuth(options);
    } catch (err) {
      if (init === "saved-tokens" && err === ERR_CANNOT_CONNECT) {
        this.cannotConnect = true;
        return;
      }
      this.error = translateErr(err);
      this.loading = false;
      return;
    } finally {
      // Clear url if we have a auth callback in url.
      if (location.search.includes("auth_callback=1")) {
        history.replaceState(null, "", location.pathname);
      }
    }

    let conn: Connection;

    try {
      conn = await createConnection({ auth });
    } catch (err) {
      // In case of saved tokens, silently solve problems.
      if (init === "saved-tokens") {
        if (err === ERR_CANNOT_CONNECT) {
          this.cannotConnect = true;
        } else if (err === ERR_INVALID_AUTH) {
          saveTokens(null);
        }
      } else {
        this.error = translateErr(err);
      }

      return;
    } finally {
      this.loading = false;
    }

    this.auth = auth;
    this.connection = conn;
  }

  private async _handleLogout() {
    try {
      saveTokens(null);
      location.reload();
    } catch (err) {
      alert("Unable to log out!");
    }
  }

  static get styles(): CSSResultGroup {
    return css`
      .card-content a {
        color: var(--primary-color);
      }
      .card-actions a {
        text-decoration: none;
      }
      .error {
        color: red;
        font-weight: bold;
      }

      .error a {
        color: darkred;
      }

      mwc-button iron-icon {
        margin-left: 8px;
      }

      .spacer {
        flex: 1;
      }
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bmw-connect": BmwConnect;
  }
}
